@import "../../../assets//scss/variable.module.scss";
.mainheadingUploaddocument {
  margin: 20px 0px 50px 0px;
  p {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: $primary-color;
    line-height: 40px;
    font-family: $family;
  }
}
.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
  }
}
.accounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  margin-top: 5px;
}

.technicalanalysisForm {
  background-color: #fff;
  padding: 20px 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  width: 100%;
  max-height: 100vh;
  &::-webkit-scrollbar {
    width: 8px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; // Background of the scrollbar track
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-color; // Color of the scrollbar thumb
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #61dafb; // Color of the scrollbar thumb on hover
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.formGroup label {
  margin-bottom: 2px;
  color: $primary-color;
  text-align: left;
  font-family: $family;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

.inputGroup {
  position: relative;
  margin: 10px 0px;
}

textarea {
  padding: 0.5rem 0.5rem 40px; // Adjusted padding for icon space
  font-size: 1rem;
  border: 1px solid $primary-color;
  border-radius: 4px;
  color: $primary-color;
  background: $input-color;
  width: 100%; // Full width
  height: 100px; // Adjust height as needed
  resize: vertical; // Allow vertical resizing only
}

.toggleIcon {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  bottom: 0%;
  color: $primary-color;
  font-size: 22px;
  gap: 10px;
  display: flex;
}

textarea::placeholder {
  color: $input-color;
  font-size: 0.875rem; // Placeholder font size
}

textarea:focus {
  outline: none;
  border-color: $primary-color;
  box-shadow: 0 0 5px hsla(0, 0%, 85%, 0.278);
}

p {
  color: $primary-color;
  font-size: 20px;
  font-weight: 500;
  font-family: $family;
}

.popuarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;
}
.popuarea p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  font-family: $family;
  text-align: center;
}
.backbtn {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 24px;
  font-weight: 500;
}

.backbtn:hover {
  color: #f3ad3d;
}
.submitButton {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  text-decoration: none;
  &:hover {
    background-color: $hover-color;
  }
}

.submitButton2 {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 80px;
  text-decoration: none;
  &:hover {
    background-color: $hover-color;
  }
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}
.linkbtn2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}

@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }
  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }
}
@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
}
@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }

  .submitButton2 {
    padding: 8px 28px;
  }
}
