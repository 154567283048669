@import "../../../assets/scss/variable.module.scss";
.whatarelooking {
  margin: 40px 0px 10px 0px;
  .mainheadinglookingfor {
    h1 {
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      color: $primary-color;
      font-family: $family;
    }
  }
  .uploaddocumentform {
    padding: 20px 0px;
    margin-top: 10px;
    .fileUploadSection {
      margin-bottom: 15px;
      .fileUploadHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
          color: $dark-color;
          font-family: $family;
        }
        .fileActions {
          display: flex;
          align-items: center;
          .iconsponts {
            display: flex;
            gap: 10px;
            span {
              color: $primary-color;
              cursor: pointer;
              width: 25px;
              height: 25px;
              cursor: pointer;
              border-radius: 50%;
              border: 2px solid $primary-color;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 3px;
            }
          }
          /*    .taxbtn {
            margin-left: 10px;
            cursor: pointer;
          } */
          .uloadbtn {
            color: $primary-color;
            background-color: transparent;
            border: 1px solid #976f1d;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s;
            font-size: 23px;
            font-weight: 500;
            text-align: center;
            padding: 5px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: $primary-color;
              color: #fff;
            }
          }

          .removeBtn {
            margin-left: 5px;
            cursor: pointer;
          }
          .fileName {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
  }
}

.popuarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;
}
.popuarea p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  font-family: $family;
  text-align: center;
}

.submitButton {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  text-decoration: none;
  &:hover {
    background-color: #c3b581;
  }
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
  &:hover {
    color: $dark-color;
    text-decoration: none;
  }
}

.linkbtn2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
  &:hover {
    color: $dark-color;
    text-decoration: none;
  }
}
@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }
  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }
}
@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
}
@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }
}
