@import "../../../assets/scss/variable.module.scss";
.projectlistpage {
  margin: 20px 0px 50px 0px;
  .projectlisttop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;

    .backbtn {
      color: #976f1d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .introText {
      text-align: center;
      h2 {
        font-size: 40px;
        color: $primary-color;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0px;
        font-family: $family;
      }
    }
  }
}
.projectListform {
  background-color: #fff;
  padding: 20px 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  width: 100%;
  max-height: 72vh;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #61dafb;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}

.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;

  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
  }
}

.accounts {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  margin-top: 5px;
  color: $primary-color;
  justify-content: center;
  gap: 50px;

  .backbtn {
    font-size: 24px;
    cursor: pointer;
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 10%);
    }
  }
}

.submitButton2 {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "JetBrains Mono", monospace;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 80px;
  text-decoration: none;

  &:hover {
    background-color: #c3b581;
  }
}

.submitButton11 {
  display: block;
  width: 100%;
  background-color: #3b3737;
  color: white;
  text-align: center;
  padding: 20px 0;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  &:hover {
    background-color: #737373; // Change background color on hover
  }
}
