// src/index.module.scss
@import "../../../assets/scss/variable.module.scss";
.supportingdocuments {
  margin: 20px 0px 50px 0px;
  .supportingdocumentstop {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    gap: 30px;

    .backbtn {
      color: #976f1d;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .introText {
      text-align: center;
      h1 {
        font-size: 36px;
        color: $primary-color;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0px !important;
        font-family: $family;
      }
    }
  }
  .projectdocument {
    background-color: #fff;
    padding: 40px 60px;
    .formGroup {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff;
      .iconarea {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px; // Adjust as necessary for spacing
        transform: translateY(-52px);
        gap: 20px;
        .uploadIcon {
          cursor: pointer;
          color: $primary-color;
        }
        .eyeIcon {
          color: $primary-color;
          cursor: pointer;
        }
        .cancelIcon {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
    .mainheading {
      text-align: center;
      align-items: center;
      margin: 20px 0px;
      justify-content: center;
      h2 {
        font-size: 40px;
        color: $primary-color;
        font-weight: 700;
      }
    }
    .inputGroup {
      position: relative; // To position icons absolutely
      margin: 10px 0px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      color: $primary-color; // Icon color
    }

    input {
      padding: 0.5rem 0.5rem 0.5rem 20px; // Adjusted padding for icon space
      font-size: 20px;
      border: 1px solid $primary-color;
      border-radius: 4px;
      color: $primary-color;
      background: $input-color;
      width: 100%; // Full width
    }

    .toggleIcon {
      position: absolute;
      top: 50%;
      right: 10px; // Position the toggle icon on the right
      transform: translateY(-50%);
      cursor: pointer; // Pointer on hover
    }

    .errorMessage {
      color: red;
      font-size: 0.875rem;
      margin-top: -0.5rem;
      margin-bottom: 1rem;
    }
    .btnareaAddmore {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0px;
      .submitButton2 {
        color: #fff;
        background-color: $primary-color;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-family: $family;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        padding: 8px 80px;
        text-decoration: none;
        &:hover {
          background-color: #c3b581;
        }
      }
    }
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  .supportingdocumentstop {
    display: flex;
    align-items: center;

    .introText {
      text-align: center;
      h1 {
        margin-bottom: 0px !important;
      }
    }
  }
}
@media (max-width: 676px) {
}
@media (max-width: 575px) {
}
@media (max-width: 480px) {
  .supportingdocumentstop {
    .introText {
      text-align: center;
      h1 {
        font-size: 28px !important;
        margin-bottom: 0px !important;
      }
    }
  }
}
@media (max-width: 420px) {
  .supportingdocumentstop {
    .introText {
      text-align: center;
      h1 {
        font-size: 24px !important;
        margin-bottom: 0px !important;
      }
    }
  }
}
