@import "../../assets/scss/variable.module.scss";
.sidebar {
  position: sticky;
  height: 100%;
  background-color: #fff;
  padding: 1rem;
  overflow-y: auto;
  display: block;
  top: -3px;
  .logo {
    width: 100px;
    height: auto;
  }

  .logo img {
    width: 100%;
    height: auto;
  }

  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin: 10px 0;
      font-size: 1.1rem;
    }
  }

  .backbtn2 {
    color: $primary-color;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: $family;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .backbtn2:hover {
    color: #f3ad3d;
  }
  .iconlock {
    padding: 0px 5px;
  }
  .backbtnactive {
    color: #f3ad3d;
  }
  .submitButton2 {
    color: #fff;
    background-color: $primary-color;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: $family;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding: 8px 0px;
    width: 80%;
    text-decoration: none;
  }
  .submitButton2:hover {
    background-color: #c3b581;
  }
}

.popuarea {
  background-color: #976f1d;
  border: 2px solid #976f1d;
  padding: 20px;
  border-radius: 8px;

  .modalActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  h4 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }
}

.modal-content {
  border: 0px !important;
}
@media (max-width: 991px) {
  .sidebar {
    display: none;
  }
}
