@import "../../../assets/scss/variable.module.scss";
.analysisSection {
  padding: 0px 40px;
  text-align: center;
  .technicalanalysis {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
    .backbtn {
      color: #976f1d;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .introText {
      font-size: 1rem;
      color: $primary-color;
      font-weight: 500;

      line-height: 1.6;
      text-align: left;
    }
  }
  .technicalanalysismain {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;

    .formGroup {
      margin-bottom: 20px;

      label {
        display: block;
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 8px;
        color: #6a6a6a;
      }

      .inputGroup {
        margin-bottom: 15px;

        .inputIcon {
          position: relative;

          .icon {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            color: #6a6a6a;
            font-size: 1.25rem;
          }

          input {
            padding-left: 40px;
          }
        }

        input,
        select {
          width: 100%;
          padding: 10px 15px;
          font-size: 1rem;
          color: #6a6a6a;
          background-color: #f9f9f9;
          border: 1px solid #ccc;
          border-radius: 4px;

          &:focus {
            outline: none;
            border-color: #a5a5a5;
          }
        }
      }
    }

    .submitBtn {
      background-color: #a39253;
      color: #fff;
      font-size: 1rem;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;

      &:hover {
        background-color: #8f7e46;
      }
    }
  }
}
@media (max-width: 991px) {
  .analysisSection {
    padding: 20px 40px;
    text-align: center;
  }
}
