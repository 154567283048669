$primary-color: #847846;
$text-color: #dda433;
$back-theme: #28282e;
$white-color: #fff;
$hover-color: #dda433;
$secondary-color: #61dafb;
$input-color: #d9d9d947;
$primary-font: "Helvetica Neue", sans-serif;
//$primary-font: "Helvetica Neue", sans-serif;

$dark-color: #000;
// font family
$family: "Helvetica Neue", sans-serif;
.mainheading {
  font-family: "Helvetica Neue", sans-serif;
  color: $primary-color;
  h1 {
    font-size: 65px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 36px;
    color: #847846;
  }
  h4 {
    font-size: 20px;
  }
  p {
    color: $primary-color;
    font-size: 20px;
    font-weight: 500;
  }
}
.mainheading2 {
  font-family: "Helvetica Neue", sans-serif;
  color: $primary-color;
  text-align: left;
  h1 {
    font-size: 65px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    color: $primary-color;
    font-size: 20px;
    font-weight: 500;
  }
}

.backbtn {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 25px;
  font-weight: 500;
}

.backbtn:hover {
  color: #f3ad3d;
}
.submitButton {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  text-decoration: none;
  &:hover {
    background-color: #dda433;
  }
}

.submitButton2 {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 80px;
  text-decoration: none;
  &:hover {
    background-color: #dda433;
  }
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}
.linkbtn2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}
.rmdp-calendar {
  height: max-content;
  padding: 4px;
  width: 500px !important;
}
.rmdp-day-picker {
  padding: 5px;
  display: block !important;
}

@media (max-width: 991px) {
  .mainheading p {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }
  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }
  .rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 450px !important;
  }
}
@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
  .rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 350px !important;
  }
}
@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }
  .rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 320px !important;
  }
  .submitButton2 {
    padding: 8px 28px;
  }
}
.modal-content {
  border: 0px !important;
}
