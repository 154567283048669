@import "../../../assets/scss/variable.module.scss";
.mainheadingUploaddocument {
  margin: 20px 0px 50px 0px;
  h2 {
    color: #976f1d;
    font-size: 20px;
    font-weight: 600;
    font-family: "JetBrains Mono", monospace;
  }
  .technicalanalysisForm {
    background-color: #fff;
    padding: 20px 60px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    width: 100%;
    max-height: 76vh;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; // Background of the scrollbar track
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color; // Color of the scrollbar thumb
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #61dafb; // Color of the scrollbar thumb on hover
    }
    .formGroup {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      label {
        margin-bottom: 2px;
        color: $primary-color;
        text-align: left;
        font-family: $family;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
      }
      .inputGroup {
        position: relative;
        margin: 10px 0px;
        .customTextarea {
          width: 100%;
          height: 200px; /* Adjust height as needed */
          border: 1px solid #ced4da;
          padding: 8px;
          border-radius: 4px;
          background-color: #f8f9fa;
          overflow-y: auto;
          resize: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 8px; /* Spacing between radio buttons */
          box-sizing: border-box;

          &:focus {
            outline: none;
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }

          .radioGroup {
            display: flex;
            margin-bottom: 5px;

            label {
              display: flex;
              align-items: center;
              cursor: pointer;
              gap: 8px;

              span {
                font-size: 14px;
              }
            }
          }
        }
      }
      textarea {
        padding: 0.5rem 0.5rem 40px; // Adjusted padding for icon space
        font-size: 1rem;
        border: 1px solid $primary-color;
        border-radius: 4px;
        color: $primary-color;
        background: $input-color;
        width: 100%; // Full width
        height: 100px; // Adjust height as needed
        resize: vertical; // Allow vertical resizing only

        &::placeholder {
          color: $input-color;
          font-size: 0.875rem; // Placeholder font size
        }

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 5px hsla(0, 0%, 85%, 0.278);
        }
      }

      .toggleIcon {
        position: absolute;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        bottom: 0%;
        color: $primary-color;
        font-size: 22px;
        gap: 10px;
        display: flex;
      }

      .customTextarea2 {
        width: 100%;
        height: 50px; /* Adjust height as needed */
        border: 1px solid #ced4da;
        padding: 8px 8px;
        border-radius: 4px;
        background-color: #f8f9fa;
        overflow-y: auto;
        resize: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 8px; /* Spacing between radio buttons */
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        .radioGroup {
          display: flex;
          margin-bottom: 5px;

          label {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 8px;

            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.mainheadingtop {
  margin-top: 20px;
}

.container {
  padding: 15px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .backbtn {
    margin-right: 10px;
    font-size: 24px;
    color: $primary-color;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      color: #f3ad3d;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: $primary-color;
}

.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;

  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
  }
}

.content {
  background-color: $white-color;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: $primary-font;
}

.paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: $text-color;
  text-align: justify;
  margin-bottom: 15px;
}

p {
  color: $primary-color;
  font-size: 20px;
  font-weight: 500;
  font-family: $family;
}

.list {
  padding-left: 20px;
  margin-bottom: 15px;

  li {
    font-size: 16px;
    line-height: 1.6;
    color: $text-color;
    text-align: justify;
    margin-bottom: 10px;
  }
}

.button,
.submitButton,
.submitButton2 {
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  color: $white-color;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
  padding: 8px 20px;
  &:hover {
    background-color: $hover-color;
  }

  &.submitButton2 {
    padding: 8px 80px;
  }
}

.linkbtn,
.linkbtn2 {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;

  &:hover {
    color: $dark-color;
    text-decoration: none;
  }

  &.linkbtn2 {
    color: #fff;
  }
}

.mainheading {
  text-align: center;
  margin-top: 20px;

  .mb-5 {
    margin-bottom: 2rem;
  }

  .submitButton2 {
    background-color: $primary-color;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $hover-color;
    }
  }

  p {
    margin: 10px 0;
  }

  .linkbtn {
    color: #0b284d;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #0a223c;
    }
  }
}

.popuarea2 {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;

  .formGroup {
    margin-bottom: 15px;
  }

  .inputGroup {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;

    .icon {
      margin-right: 10px;
      color: #0b284d;
    }

    input {
      border: none;
      outline: none;
      flex-grow: 1;
      padding: 8px;
      font-size: 16px;

      &::placeholder {
        color: #aaa;
      }
    }
  }
}

.popuarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }
}

.accounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-top: 5px;
  gap: 50px;

  @media (max-width: 767px) {
    justify-content: center;
  }
}

// Responsive Styles
@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }
  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }
  .customTextarea2 {
    height: 60px; /* Adjust height as needed */

    padding: 2px 8px;
  }
}

@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
}

@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }
  .submitButton2 {
    padding: 8px 28px;
  }
}
