@import "../../../assets/scss/variable.module.scss";
.whatarelooking {
  margin: 40px 0px 10px 0px;
  .mainheadingtop {
    margin: 10px 0px;
    h1 {
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      color: $primary-color;
      line-height: 40px;
      font-family: $family;
    }
    p {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      color: $primary-color;
      line-height: 40px;
      font-family: $family;
    }
  }
  .uploaddocumentForm {
    padding: 20px 0px;
    margin-top: 10px;
    .accounts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-top: 5px;
      color: $primary-color;
      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        text-align: left;
        color: $dark-color;
        font-family: $family;
      }
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        text-align: left;
        color: #484848;
      }
    }
    .accounts2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-top: 5px;
      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: $dark-color;
        font-family: $family;
      }
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        text-align: left;
        color: #484848;
      }
    }
    .accounts3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px;
      margin-top: 5px;
      color: $primary-color;
    }
  }
}

.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
    font-family: $family;
  }

  h3 {
    color: $primary-color;
    font-family: $family;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    text-align: center;
  }
}
.fileActions {
  display: flex;
  align-items: center;
  .iconsponts {
    display: flex;
    gap: 10px;
    span {
      color: $primary-color;
      cursor: pointer;
      width: 25px;
      height: 25px;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 3px;
    }
  }

  .uloadbtn {
    color: $primary-color;
    background-color: transparent;
    border: 1px solid #976f1d;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

.popuarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;
}
.popuarea p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  font-family: $family;
  text-align: center;
}

.submitButton {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  text-decoration: none;
  &:hover {
    background-color: #c3b581;
  }
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
  &:hover {
    color: $dark-color;
    text-decoration: none;
  }
}

.linkbtn2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
  &:hover {
    color: $dark-color;
    text-decoration: none;
  }
}
@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }
  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }
}
@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
}
@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }

  .submitButton2 {
    padding: 8px 28px;
  }
}
