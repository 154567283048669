@import "../../../assets/scss/variable.module.scss";
.whatarelooking {
  margin: 30px 20px 30px 20px;
}
.whatarelooking .textcardarea {
  background-color: $primary-color;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 20px 0px;
}
.textcardarea p {
  color: #fff;
  font-size: 18px;
  $font-family: "Helvetica Neue", sans-serif;
}
.mainheadinglookingfor h5 {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: $primary-color;
  font-family: "Helvetica Neue", sans-serif;
}
.LookingforForm {
  border: 1px solid $primary-color;
  border-radius: 8px;
  padding: 40px 0px;
  margin-top: 40px;
  text-align: center;
}

.taxbtn {
  color: $primary-color;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  width: 80%;
}

.taxbtn:hover {
  background-color: $primary-color;
  color: #fff;
}
.backbtn {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 25px;
  font-weight: 500;
}

.backbtn:hover {
  color: #f3ad3d;
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}
.linkbtn2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}

@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }
  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }
}
@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
}
@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }
}
