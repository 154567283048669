@import "../../assets/scss/variable.module.scss";

.homesection1st {
  background-color: #28282e;
  position: relative;
  .yourfinance {
    text-align: center;
    padding: 80px 70px 10px 70px;
    h1 {
      font-size: 64px;
      color: $primary-color;
      font-family: $family;
      font-weight: 600;
      line-height: 50px;
    }
    h2 {
      font-size: 48px;
      color: #fff;
      font-family: $family;
      font-weight: 500;
    }

    .pergraph {
      text-align: center;
      padding: 20px 80px;
      p {
        font-size: 20px;
        color: $primary-color;
      }
    }
    .searchContainer {
      display: flex;
      margin-top: 1rem;
      width: 75%;
      justify-content: center;
      align-items: center;
      position: relative;
      .searchInput {
        flex: 1;
        padding: 0.5rem 20px;
        border: 2px solid $primary-color;
        border-radius: 50px;
        outline: none;
        color: #333;
        &::placeholder {
          color: $primary-color;
          font-family: $family;
          font-size: 20px;
        }
      }

      .searchButton {
        padding: 10px 20px;
        background-color: $primary-color;
        color: #fff;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        position: absolute;
        right: 0;
        font-family: $family;
        font-size: 18px;
        &:hover {
          background-color: $hover-color;
        }
      }
    }
    .searchphoneview {
      display: none;
    }
    .smallText {
      font-size: 14px;
      color: $primary-color;
      font-family: $family;
    }
  }
}
.homesection1stphone {
  display: none;
}
.homesection2nd {
  background-color: #fff;
  .bannertopimage {
    background-image: url(../../assets/images/shapes/7thbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    bottom: 46px;
    left: 0;
    width: 100%;
    z-index: 2;
    .paddingarea {
      padding: 45px 0px;
    }
  }
  .thereimagepart {
    padding: 70px 0px 50px 0px;
    .therepartpadding {
      padding: 0px 0px;
      h2 {
        font-size: 42px;
        font-family: $family;
        color: $primary-color;
        font-weight: 700;
      }
      .blogscard {
        cursor: pointer;
        padding: 0px 0px;
        .imgpointcontent {
          width: 100%;
          height: 300px;
          border-radius: 15px;
          overflow: hidden;
          background-color: #28282e;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        p {
          font-size: 22px;
          color: #000000;
          font-family: $family;
          padding: 20px 0px;
          line-height: 30px;
        }
      }
    }
  }
}
.homesection3rd {
  background-color: #28282e;
  .padingstopbottom {
    padding: 40px 0px;
  }
  .threetopimage {
    background-image: url(../../assets/images/shapes/3rdbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    top: -2px;
    .paddingarea {
      padding: 40px 0px;
    }
  }
  .imgpointbig {
    width: auto;
    height: 100%;
    float: left;
    border-radius: 15px;

    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .hedingbig {
    p {
      margin-bottom: 0px;
    }
    h2 {
      font-size: 34px;
      font-family: $family;
      color: #fff;
      font-weight: 700;
    }
    h3 {
      font-size: 18px;
      font-family: $family;
      color: #fff;
      font-weight: 700;
    }
  }
  .textpartbig {
    padding: 0px 30px;
    h2 {
      font-size: 34px;
      border-radius: 50%;
      border: 2px solid $primary-color;
      color: $primary-color;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      font-weight: 700;
    }
    h3 {
      font-size: 34px;
      color: #fff;
      font-family: $family;
      padding: 10px 0px 0px 0px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
      color: #fff;
      font-family: $family;
      padding: 10px 0px 0px 0px;
      margin-bottom: 0px;
      span {
        font-size: 22px;
        color: #fff;
        font-family: $family;
        padding: 10px 0px;
        margin-bottom: 5px;
      }
    }

    .submitButton2 {
      color: #fff;
      background-color: $primary-color;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-family: $family;
      font-size: 20px;

      font-weight: 500;
      text-align: center;
      padding: 8px 50px;
      text-decoration: none;

      &:hover {
        background-color: #dda433;
      }
    }
  }
  .textpartbig3 {
    padding: 0px 30px;
    h2 {
      font-size: 34px;
      border-radius: 50%;
      border: 2px solid $primary-color;
      color: $primary-color;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      font-weight: 700;
    }
    h3 {
      font-size: 34px;
      color: #fff;
      font-family: $family;
      padding: 10px 0px 0px 0px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
      color: #fff;
      font-family: $family;
      padding: 10px 0px 0px 0px;
      margin-bottom: 0px;
      span {
        font-size: 20px;
        color: #fff;
        font-family: $family;
        padding: 10px 0px;
        margin-bottom: 5px;
      }
    }

    .submitButton2 {
      color: #fff;
      background-color: $primary-color;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-family: $family;
      font-size: 20px;

      font-weight: 500;
      text-align: center;
      padding: 8px 50px;
      text-decoration: none;

      &:hover {
        background-color: #dda433;
      }
    }
  }
  .threebottomimage {
    background-image: url(../../assets/images/shapes/4thbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    bottom: -3px;
    .paddingarea {
      padding: 41px 0px;
    }
  }
}
.homesection4th {
  background-color: #28282e;
  padding: 60px 0px 40px;
  .rightleftsides {
    padding: 0px 0px 40px 0px;
    .leftsidetext {
      p {
        font-size: 20px;
        font-weight: 400;
        font-family: $family;
        color: $dark-color;
        text-decoration: underline;
      }
      h2 {
        font-size: 38px;
        font-weight: 700;
        font-family: $family;
        color: #fff;
      }
      .peraghraph {
        padding: 20px 0px;
        p {
          font-size: 20px;
          font-weight: 500;
          font-family: $family;
          color: #fff;
          text-decoration: none;
        }
      }
      .unlockthepotential {
        .submitButton2 {
          color: #fff;
          background-color: $primary-color;
          border: none;
          width: 90%;
          border-radius: 50px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-family: $family;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          padding: 16px 16px;
          text-decoration: none;
          line-height: 24px;
          &:hover {
            background-color: #dda433;
          }
        }
      }
    }
    .rightsidetext {
      .imgpointbig {
        width: auto;
        height: 100%;
        float: left;
        border-radius: 15px;

        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .custom_hr {
        color: $dark-color;
        height: 3px;
        background-color: $dark-color;
        opacity: inherit;
      }
      h2 {
        font-size: 50px;
        font-weight: 700;
        font-family: $family;
        color: #fff;
      }
      p {
        font-family: $family;
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .kularcompare {
    .custom_hr {
      color: $dark-color;
      height: 3px;
      background-color: $dark-color;
      opacity: inherit;
    }
    h2 {
      font-size: 36px;
      font-weight: 700;
      font-family: $family;
      color: #fff;
    }

    .kularcomparebar {
      padding: 50px 0px;
      h4 {
        font-family: $family;
        color: #fff;
        font-size: 20px;
      }
      .firstbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000000;
        margin: 20px 0px;
        padding: 30px 20px;
        opacity: 0.4;
        h3 {
          font-size: 36px;
          font-weight: 700;
          font-family: $family;
          color: #fff;
        }
      }
    }
  }
}
.homesection5th {
  background-image: url(../../assets/images/shapes/bigman.svg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  top: -3px;
  .textmanara {
    padding: 180px 0px;
    text-align: left;
    p {
      font-size: 32px;
      font-family: $family;
      color: #ffffff;
      font-weight: 600;
    }
  }
  .forbottomimage {
    background-image: url(../../assets/images/shapes/5thbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    top: -5px;
    .paddingarea {
      padding: 40px 0px;
    }
  }
  .bottomaraname {
    h4 {
      font-size: 28px;
      font-family: $family;
      color: $dark-color;
      font-weight: 600;
    }
    h5 {
      font-size: 36px;
      font-family: $family;
      color: #ffffff;
      font-weight: 600;
    }
  }
  h3 {
    font-size: 24px;
    font-family: $family;
    color: #fff;
    text-decoration: underline;
    font-weight: 700;
  }
  .bigpaddingarea {
    padding: 50px 0px;
  }
  .bannertopimage {
    background-image: url(../../assets/images/shapes/videoaratop.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    bottom: -3px;
    .paddingarea {
      padding: 40px 0px;
    }
  }
}
.homesection6th {
  background-color: #f2f2f2;
  .aisalescontainer {
    padding: 60px 0px;
    .aisalesimage {
      width: 100%;
      height: auto;
      float: left;
      border-radius: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .aisalestext {
      padding: 0px 20px;
      h2 {
        font-size: 20px;
        font-family: $family;
        font-weight: 600;
        text-decoration: underline;
        padding: 10px 0px;
        color: $dark-color;
      }
      h3 {
        font-size: 32px;
        font-family: $family;
        font-weight: 700;
        color: $dark-color;
        padding: 10px 0px;
      }
    }
  }
}
.homesection7th {
  background-color: #28282e;
  position: relative;
  .bannertopimage {
    background-image: url(../../assets/images/shapes/7thbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2;
    .paddingarea {
      padding: 25px 0px;
    }
  }
  /*   .bannertopimage {
    background-image: url(../../assets/images/shapes/6thbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    top: -3px;
    .paddingarea {
      padding: 40px 0px;
    }
  } */
  .thereimagepart {
    padding: 30px 0px 50px 0px;

    .therepartpadding {
      .headerpoints {
        padding: 20px 0px;
        h2 {
          font-size: 38px;
          font-weight: 700;
          font-family: $family;
          color: $primary-color;
          line-height: 35px;
        }
        h4 {
          font-size: 38px;
          font-weight: 400;
          font-family: $family;
          color: #fff;
        }
      }
      .carouselItem {
        padding: 0px 10px;
        .imgpointcontent {
          width: 100%;
          height: 300px;
          border-radius: 15px;
          overflow: hidden;
          background-color: #000;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        p {
          font-size: 22px;
          color: #fff;
          font-family: $family;
          padding: 20px 0px;
          line-height: 30px;
        }
      }
    }
  }
}
.homesection8th {
  background-color: #28282e;
  position: relative;

  .bannerimage {
    background-image: url(../../assets/images/shapes/1stbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: none !important;
    .videowithimage {
      padding: 11% 0px 0px 0%;
      text-align: center;
      display: flex;
      justify-content: center;
      .mainvedio {
        width: 100%;
        display: grid;
        place-items: center;
        overflow: hidden;
        position: relative;
        .jomevideoarea {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
    }
  }
}
.homesection9th {
  background-color: #28282e;
  position: relative;

  .bannertopimage {
    background-image: url(../../assets/images/shapes/5thbackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    z-index: 2;
    top: -2px;
    .paddingarea {
      padding: 40px 0px;
    }
  }
  .hedingbig {
    p {
      font-size: 20px;
      font-family: $family;
      color: $primary-color;
      font-weight: 500;
      margin-bottom: 0px;
    }
    h2 {
      font-size: 34px;
      font-family: $family;
      color: #fff;
      font-weight: 700;
    }
    h3 {
      font-size: 20px;
      font-family: $family;
      color: #fff;
      font-weight: 700;
    }
  }
  .videosection {
    padding: 0px 0px 30px 0px;
    position: relative; /* Ensures child elements respect layering */
    z-index: 1; /* Places it below the .forbottomimage content */

    .videopart {
      padding: 0px 0px 0px 0px;
      text-align: center;

      .jomevideoarea {
        text-align: center;
        width: 100%;
        height: auto;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.homesection10th {
  background-color: $back-theme;
  padding: 0px !important;

  .bannercards {
    padding: 20px 0px 110px;
    /*  .peragraph {
      padding: 30px;
      p {
        padding: 0px;
        color: #fff;
        font-size: 22px;
        font-family: $family;
        span {
          padding: 0px;
          color: #fff;
          font-size: 18px;
          font-family: $family;
          .newslogo {
            width: 50px;
            height: 50px;
            img {
              height: auto;
              width: 100%;
            }
          }
        }
      }
    } */
    .nameimagearea {
      padding: 0px 20px;
      display: block;
      .imgpoint {
        width: 100%;
        height: auto;
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.footer {
  box-shadow: rgba(0, 0, 0, 0.24) 0px -3px 8px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  animation: fadeInUp 3s ease-out forwards;
}

@media (max-width: 991px) {
  .homesection3rd {
    .textpartbig {
      padding: 30px 0px 30px 0px;

      .submitButton2 {
        padding: 8px 20px;
      }
    }
    .textpartbig3 {
      padding: 30px 0px 30px 0px;

      .submitButton2 {
        padding: 8px 20px;
      }
    }
  }
  .rightleftsides {
    padding: 0px 0px !important;
  }
  .unlockthepotential {
    padding: 0px 0px 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homesection1st {
    display: none;
  }
  .homesection1stphone {
    display: block;
    background-color: #28282e;
    position: relative;
    .yourfinance {
      text-align: center;
      padding: 70px 10px 10px 10px;
      h1 {
        font-size: 52px;
        line-height: 50px;
        color: $primary-color;
        font-family: $family;
        font-weight: 600;
      }
      h2 {
        font-size: 48px;
        color: #fff;
        font-family: $family;
        font-weight: 500;
      }
      .pergraph {
        text-align: center;
        padding: 20px 10px;
        p {
          font-size: 20px;
          color: #6e674a;
        }
      }

      .searchContainer {
        display: none;
      }
      .searchphoneview {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;

        .searchInput {
          width: 100%;
          max-width: 600px;
          padding: 0.5rem 20px;
          border: 2px solid $primary-color;
          border-radius: 50px;
          outline: none;
          color: #333;

          &::placeholder {
            color: $primary-color;
            font-family: $family;
            font-size: 20px;
          }
        }

        .searchButton {
          margin-top: 1rem;
          padding: 10px 20px;
          background-color: $primary-color;
          color: #fff;
          border: none;
          cursor: pointer;
          border-radius: 50px;
          font-family: $family;
          font-size: 18px;

          &:hover {
            background-color: $hover-color;
          }
        }
      }
      .smallText {
        font-size: 12px;
        color: #7c796c;
        font-family: $family;
      }
    }
  }

  .homesection8th {
    .bannerimage {
      .videowithimage {
        padding: 14% 0px 0px 0%;
        .mainvedio {
          width: 95%;
          display: grid;
          place-items: center;
          overflow: hidden;
          position: relative;
        }
      }
    }
  }
  .homesection9th {
    background-color: #28282e;
    position: relative;

    .bannertopimage {
      top: -3px;
      width: 100%;
      z-index: 2;
      .paddingarea {
        padding: 20px 0px;
      }
    }
  }
  .homesection7th {
    background-color: #28282e;
    position: relative;
    .bannertopimage {
      bottom: -2px;
      left: 0;
      width: 100%;
      z-index: 2;
      .paddingarea {
        padding: 20px 0px !important;
      }
    }
  }
}
@media (max-width: 790px) {
  .homesection8th {
    .bannerimage {
      background-image: url(../../assets/images/shapes/1stbackground.svg);
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .videowithimage {
        .mainvedio {
          width: 90%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .blogscard {
    cursor: pointer;
    background-color: $primary-color;
    margin: 10px 0px;
    padding: 10px !important;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    p {
      font-size: 20px !important;
      color: #fff !important;
      padding: 20px 10px 0px 10px !important;
    }
  }
  .homesection8th {
    .bannerimage {
      .videowithimage {
        padding: 9% 0px 10px 0% !important;
        .mainvedio {
          width: 100% !important;
        }
      }
    }
  }
  .homesection3rd {
    .imgpointbig {
      margin: 0px 0px 40px 0px;
    }
    .textpartbig3 {
      padding: 20px 30px;

      p {
        font-size: 17px;

        span {
          font-size: 17px;
        }
      }

      .submitButton2 {
        margin: 0px 0px 20px 0px;
      }
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 28px;
      }
    }
    .textpartbig {
      padding: 20px 30px;
      p {
        font-size: 18px;
        span {
          font-size: 18px;
        }
      }
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 28px;
      }
      .submitButton2 {
        margin: 0px 0px 20px 0px;
      }
    }
  }
}
@media (max-width: 664px) {
  .homesection3rd {
    .padingstopbottom {
      padding: 0px 0px !important;
    }
    .imgpointbig {
      padding: 0px 32px !important;
    }
  }
  .homesection1stphone {
    .yourfinance {
      text-align: center;
      padding: 70px 10px 10px 10px;
      h1 {
        font-size: 52px;
        line-height: 50px;
      }
      h2 {
        font-size: 48px;
      }
      .pergraph {
        text-align: center;
        padding: 10px 5px;
        p {
          font-size: 17px;
          color: #6e674a;
        }
      }

      .searchContainer {
        display: none;
      }
      .searchphoneview {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 5px;
        box-sizing: border-box;

        .searchInput {
          width: 100%;
          max-width: 600px;
          padding: 0.5rem 20px;
          border: 2px solid $primary-color;
          border-radius: 50px;
          outline: none;
          color: #333;

          &::placeholder {
            color: $primary-color;
            font-family: $family;
            font-size: 16px;
          }
        }

        .searchButton {
          margin-top: 1rem;
          padding: 10px 20px;
          background-color: $primary-color;
          color: #fff;
          border: none;
          cursor: pointer;
          border-radius: 50px;
          font-family: $family;
          font-size: 16px;

          &:hover {
            background-color: $hover-color;
          }
        }
      }
      .smallText {
        font-size: 12px;
        color: #7c796c;
        font-family: $family;
      }
    }
  }
  .homesection8th {
    .bannerimage {
      .videowithimage {
        padding: 9% 0px 0px 0% !important;

        .mainvedio {
          width: 90% !important;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .homesection1stphone {
    .yourfinance {
      text-align: center;
      padding: 40px 10px 10px 10px;
      h1 {
        font-size: 36px;
      }
      h2 {
        font-size: 28px;
      }
    }
  }

  .homesection8th {
    .bannerimage {
      .videowithimage {
        padding: 11% 0px 0px 0% !important;

        .mainvedio {
          width: 80% !important;
        }
      }
    }
  }
  .homesection9th {
    background-color: #28282e;
    position: relative;

    .bannertopimage {
      top: -3px;
      width: 100%;
      z-index: 2;
      .paddingarea {
        padding: 13px 0px;
      }
    }
  }
  .homesection7th {
    background-color: #28282e;
    position: relative;
    .bannertopimage {
      bottom: -2px;
      left: 0;
      width: 100%;
      z-index: 2;
      .paddingarea {
        padding: 13px 0px !important;
      }
    }
  }
}
@media (max-width: 480px) {
  .homesection3rd {
    .textpartbig {
      padding: 10px 10px;

      .submitButton2 {
        padding: 8px 20px;
      }
      h2 {
        font-size: 28px;
      }
      h3 {
        font-size: 26px;
      }
    }
    .textpartbig3 {
      padding: 10px 10px;

      .submitButton2 {
        padding: 8px 20px;
      }
      h2 {
        font-size: 28px;
      }
      h3 {
        font-size: 26px;
      }
    }
  }
  .homesection1stphone {
    .yourfinance {
      text-align: center;
      padding: 40px 10px 10px 10px !important;
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 26px;
      }
    }
  }
}
/* ===================== */
/* ====Big screens== */
@media (max-width: 1200px) and (min-width: 1000px) {
  .homesection1st {
    .yourfinance {
      text-align: center;
      padding: 80px 10px 10px 10px;
      .pergraph {
        text-align: center;
        padding: 20px 0px;
      }
      .searchContainer {
        width: 95% !important;
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .homesection1st {
    .yourfinance {
      text-align: center;
      padding: 80px 10px 10px 10px;
      .searchContainer {
        width: 80% !important;
      }
    }
  }
}

@media (max-width: 1700px) and (min-width: 1400px) {
  .homesection8th {
    .bannerimage {
      background-image: url(../../assets/images/shapes/1stbackground.svg);
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .videowithimage {
        padding: 17% 0px 0px 0%;
        .mainvedio {
          width: 90%;
        }
      }
    }
  }
}
@media (max-width: 2399px) and (min-width: 1700px) {
  .homesection1st {
    background-color: #28282e;
    position: relative;
    padding: 0px 0px 30px 0px;
  }

  .homesection8th {
    .bannerimage {
      background-color: none;
      background-image: url(../../assets/images/shapes/responsivbackground1st.svg);
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      .videowithimage {
        padding: 0% 0px 0% 0%;
        .mainvedio {
          width: 90%;
        }
      }
    }
  }
}
@media (min-width: 2400px) {
  .homesection1st {
    background-color: #28282e;
    position: relative;
    padding: 0px 0px 30px 0px;
  }

  .homesection8th {
    .bannerimage {
      background-color: none;
      background-image: url(../../assets/images/shapes/responsivbackground1st.svg);
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      .videowithimage {
        padding: 0% 0px 0% 0%;
        .mainvedio {
          width: 90%;
        }
      }
    }
  }

  .homesection9th {
    background-color: #28282e;
    position: relative;

    .videosection {
      padding: 40px 0px 30px 0px;
      position: relative; /* Ensures child elements respect layering */
      z-index: 1; /* Places it below the .forbottomimage content */
    }
  }
}

@media (max-height: 552px) {
  .homesection1st{
    .yourfinance{
      padding: 40px 10px 10px 10px !important;

      .pergraph{
        padding: 10px 0px 0px !important;
      }
    }

  }
}
