@import "../../assets/scss/variable.module.scss";
* {
  margin: 0;
  padding: 0;
}
.headermain {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.header {
  background-color: transparent;
}
.mainheader {
  padding: 0px !important;
  background-color: #fff;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  position: relative;
}
.navbar {
  display: block;
  z-index: 1;
  background-color: rgba(255, 192, 203, 0.5); // transparent pink
  position: fixed;
  width: 100%; // Ensure the navbar takes the full width
}
.navlink {
  font-size: 32px;
  color: $primary-color;
}

.navlinksetting {
  font-size: 32px;
  color: $primary-color;
  display: none;
}
.logoimge {
  width: auto;
  height: 100px;
  padding: 0 !important;
  margin: 0 !important;

  img {
    width: 100%;
    height: 100%; /* Maintain aspect ratio */
    display: block; /* Remove any extra space around images */
  }
}

.navbar .navbar-nav .nav-link {
  color: $dark-color; // Change text color for links
  &:hover {
    color: #007bff; // Change color on hover
  }
}
.accounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-top: 5px;
  color: $primary-color;
  gap: 25px;
}
.accounts h3 {
  padding: 0px 10px;
  margin-top: 5px;
  font-size: 25px;
}
ul {
  list-style-type: none;
  padding: 0;
  li {
    margin: 10px 0;
    font-size: 1.1rem;
  }
}
.backbtn {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  top: 18px;
  right: 15px;
  &:hover {
    color: #f3ad3d;
  }
}

.backbtn2 {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  &:hover {
    color: #f3ad3d;
  }
}
.iconlock {
  padding: 0px 10px;
}
.backbtn:hover {
  color: #f3ad3d;
}

.popuarea {
  background-color: #976f1d;
  border: 2px solid #976f1d;
  padding: 20px;
  border-radius: 8px;

  .modalActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  h4 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    font-family: $family;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }
}
.submitButton2 {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 0px;
  width: 80%;
  text-decoration: none;
}
.submitButton2:hover {
  background-color: #c3b581;
}
.backbtnactive {
  color: #f3ad3d;
}
@media (max-width: 991px) {
  .navpoints {
    display: flex;
    gap: 5px;
    align-items: center;
    background: #fff;
    padding: 5px 10px;
    right: 10px;
  }
  /*  .navpoints {
    display: flex;
    gap: 5px;
    align-items: center;
    background: #fff;
    margin-top: 40px;
    position: absolute;
    padding: 5px 10px;
    right: 10px;
    //top: 10px;
  } 
      .navlink {
    font-size: 22px;
    color: $primary-color;
    cursor: pointer;
  }
   .navlinksetting {
    font-size: 22px;
    color: $primary-color;
    display: block;
    cursor: pointer;
  }
    */
  .navlink {
    font-size: 35px;
    color: $primary-color;
    cursor: pointer;
  }
  .navlinksetting {
    font-size: 35px;
    color: $primary-color;
    display: block;
    cursor: pointer;
  }
}
@media (max-width: 575px) {
  .logoimge {
    width: auto;
    height: 80px;
    padding: 0 !important;
    margin: 0 !important;

    img {
      width: 100%;
      height: 100%; /* Maintain aspect ratio */
      display: block; /* Remove any extra space around images */
    }
  }
}
