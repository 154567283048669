// src/index.module.scss
@import "../../../assets/scss/variable.module.scss";
.otpVerificationSection {
  margin: 60px 0px;
  .signtop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;

    .backbtn {
      color: #976f1d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .introText {
      text-align: center;
      h2 {
        font-size: 40px;
        color: $primary-color;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0px;
        font-family: $family;
      }
    }
  }
  .accounts {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    margin-top: 5px;
    color: $primary-color;
    justify-content: center;
    gap: 50px;
  }
  .otpverificationForm {
    background-color: #fff;
    padding: 40px 60px;
    .submitButton2 {
      color: #fff;
      background-color: $primary-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-family: $family;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      padding: 8px 80px;
      text-decoration: none;
      &:hover {
        background-color: $hover-color;
      }
    }
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    padding: 40px 60px;
    .otpInput {
      width: 3rem !important;
      height: 3rem;
      font-size: 1.5rem;
      text-align: center;
      margin: 0 0.5rem;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
}

// page.module.scss

.errorMessage {
  color: red;
  font-size: 0.875rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
  }
}

.inputGroup {
  position: relative; // To position icons absolutely
  margin: 10px 0px;
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}
.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}

@media (max-width: 1161px) {
  .signupForm {
    background-color: #fff;
    padding: 40px 20px;
  }
}
@media (max-width: 991px) {
  .signupForm {
    background-color: #fff;
    padding: 40px 20px;
  }
}
@media (max-width: 768px) {
  .mainheading h2 {
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }
}
@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }
}
