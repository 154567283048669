.profit-loss-container {
    width: 100%;
    margin: 0 auto;
    color: #333;
    text-align: center;

  
    .section {
      margin-top: 20px;
      border-radius: 4px;
    }
  
    .row {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #ccc;
  
      &:last-child {
        border-bottom: none;
      }
  
      span {
        flex: 1;
        text-align: left;
  
        &:last-child {
          text-align: right;
        }
      }
  
      &.section-header {
        background-color: #f0f0f0;
        font-weight: bold;
      }
  
      &.bold {
        font-weight: bold;
      }
    }
  }
  