@import "../../../assets/scss/variable.module.scss";

.instructionspage {
  margin: 20px 0px;

  .instructionstop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 70px;
    margin-bottom: 30px;

    .backbtn {
      color: #976f1d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .introText {
      text-align: center;

      h2 {
        font-size: 64px;
        font-weight: 600;
        color: $primary-color;
        line-height: 70px;
        margin-bottom: 0px;
        font-family: $family;
      }
    }
  }

  .content {
    background-color: $white-color;
    padding: 40px 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    a{
      color: $primary-color;

      &:hover{
        text-decoration: underline !important;
      }
    }

    .paragraph {
      font-size: 16px;
      line-height: 1.6;
      color: $text-color;
      text-align: justify;
      margin-bottom: 15px;
    }

    h5{
      margin-bottom: 18px;
    }

    .list {
      padding-left: 20px;
      margin-bottom: 20px;

      li {
        font-size: 16px;
        line-height: 1.6;
        text-align: justify;
        margin-bottom: 10px;

        span{
          font-weight: 500;
        }
      }
    }
  }
  
}

.nextbtn {
  background-color: $primary-color;
  border: none;
  border-radius: 5px;
  color: $white-color;
  font-size: 18px;
  font-weight: 600;
  padding: 17px 36px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hover-color;
  }
}