@import "../../assets/scss/variable.module.scss";
* {
  margin: 0;
  padding: 0;
}

.header {
  background-color: transparent;
}
.mainheader {
  padding: 5px 0px !important;
  background-color: #28282e;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  position: relative;
  .navbarcollapse {
    padding: 0px 10px;
    .togglebtn {
      background-color: #fff !important;
    }
  }
}
.headerscroll {
  background-color: #28282e;
  top: -1px;
  left: 0;
  right: 0;
  position: sticky !important;
  width: 100% !important;
  z-index: 10;
  box-shadow: none;
}
.navpoints {
  display: flex;
  gap: 25px;
  align-items: center;
  .navlink {
    font-size: 18px;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Helvetica Neue", sans-serif;
    // word-spacing: -4px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
  .customNavDropdown {
    .navpoints2 {
      display: block;
      gap: 5px;
      align-items: center;
      background: #fff;
      margin-top: 0px;
      position: absolute;
      z-index: 20;
      padding: 10px 10px;
      right: 10px;
      border-radius: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .navlink {
        color: $primary-color;
        cursor: pointer;
        &:hover {
          color: $dark-color;
        }
      }
    }
  }
}

.logoimge {
  width: auto;
  height: 100px;
  padding: 5px 0px !important;
  margin: 0 !important;

  img {
    width: 100%;
    height: 100%; /* Maintain aspect ratio */
    display: block; /* Remove any extra space around images */
  }
}

.navbar .navbar-nav .nav-link {
  color: $dark-color;
  &:hover {
    color: #007bff;
  }
}
.accounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-top: 5px;
  color: $primary-color;
  gap: 25px;
}
.accounts h3 {
  padding: 0px 10px;
  margin-top: 5px;
  font-size: 25px;
}
ul {
  list-style-type: none;
  padding: 0;
  li {
    margin: 10px 0;
    font-size: 1.1rem;
  }
}
.backbtn {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 24px;
  font-weight: 500;
}

.backbtn:hover {
  color: #f3ad3d;
}
.backbtn2 {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.iconlock {
  padding: 0px 5px;
}
.backbtn:hover {
  color: #f3ad3d;
}

.popuarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;

  .modalActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  h4 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    font-family: $family;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.popuarealoking {
  .modal_header {
    border: none !important;
  }
}
@media (max-width: 991px) {
  .navpoints {
    display: block;
    gap: 5px;
    align-items: center;
    background: #fff;
    margin-top: 20px;
    position: absolute;
    border-radius: 10px;
    padding: 5px 10px;
    right: 10px;
    z-index: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .navlink {
      font-size: 16px;
      color: $primary-color;
      &:hover {
        color: #28282e;
      }
    }
  }
  .mainheader {
    .navbarcollapse {
      padding: 0px 10px;
      .togglebtn {
        background-color: #fff !important;
        padding: 6px !important;
      }
    }
  }
}
@media (max-width: 575px) {
  .mainheader {
    .containerfluid {
      padding: 0px 10px;
    }
  }
  .navbarcollapse {
    padding: 0px 10px;
  }
  .logoimge {
    width: auto;
    height: 80px;
    padding: 0 !important;
    margin: 0 !important;

    img {
      width: 100%;
      height: 100%; /* Maintain aspect ratio */
      display: block; /* Remove any extra space around images */
    }
  }
}
@media (max-width: 320px) {
  .mainheader {
    .containerfluid {
      padding: 0px 5px;
    }
  }
  .logoimge {
    width: auto;
    height: 60px !important;
    padding: 0 !important;
    margin: 0 !important;

    img {
      width: 100%;
      height: 100%; /* Maintain aspect ratio */
      display: block; /* Remove any extra space around images */
    }
  }
  .navbarcollapse {
    padding: 0px 5px;
  }
}
@media (max-width: 220px) {
  .logoimge {
    height: 50px !important;
  }
}
