// src/styles/globals.scss
// Correct path for your variables file

@import "../assets/scss/variable.module.scss";

body {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.logo {
  width: 100px; // Set the width of the logo to 100px
  height: auto;
}

.logo img {
  width: 100%; // Set the width of the logo to 100px
  height: auto; // Maintain aspect ratio
}

.section_padding {
  padding: 40px 40px;
}

.section_paddingdelete {
  padding: 180px 200px 40px 200px;
}

.main_heading h1 {
  font-size: 64px;
  color: $primary-color;
  font-family: $family;
  line-height: 70px;
  font-weight: 600;
}

.main_heading h2 {
  font-size: 48px;
  color: $primary-color;
  font-family: $family;
  font-weight: 500;
}

.main_heading h3 {
  font-size: 24px;
  color: $primary-color;
  font-family: $family;
}

.main_heading h5 {
  font-size: 24px;
  color: $primary-color;
  font-family: $family;
}

.heading_textarea {
  h2 {
    font-size: 34px;
    color: $primary-color;
    font-family: $family;
    font-weight: 600;
  }

  h3 {
    font-size: 34px !important;
    color: $primary-color;
    font-family: $family;
    font-weight: 600;
    text-align: center;
  }
}

.contactheading {
  background-color: $primary-color !important;

  .heading_textarea {
    text-align: center;

    h2 {
      font-size: 30px;
      font-family: $family;
      font-weight: 700;
      color: $white-color;
    }

    p {
      font-size: 20px;
      color: $primary-color;
      align-items: center;
      font-family: $family;
      line-height: 1.625;
      margin-top: 10px;
    }

    ul {
      padding: 0px 40px;

      li {
        font-size: 18px !important;
        color: $primary-color;
        align-items: center;
        font-family: $family;
        margin-top: 10px;
      }
    }
  }
}

.section_about {
  background-color: $back-theme;

  .headerpart {
    text-align: center;
    justify-content: center;
    padding: 60px 0px;

    h1 {
      font-size: 64px;
      color: $primary-color;
      font-family: $family;
      font-weight: 600;
      line-height: 70px;
    }

    h2 {
      font-size: 48px;
      color: #fff;
      font-family: $family;
      font-weight: 500;
    }
  }

  .abutotextarea {
    background-color: $primary-color;
    border-radius: 20px;
    margin: 0px 0px 90px 0px;
    padding: 20px 20px;

    .heading_textarea {
      h2 {
        font-size: 34px;
        font-family: $family;
        font-weight: 700;
        color: #000;
        text-align: left;
      }

      h3 {
        font-size: 28px;
        font-family: $family;
        font-weight: 700;
        color: #000;
        text-align: left;
      }

      p {
        font-size: 20px;
        color: #fff;
        align-items: center;
        font-family: $family;
        line-height: 25px;
        margin-top: 10px;

        span {
          font-size: 19px;
          color: #fff;
          font-weight: 800;
          font-family: $family;
        }
      }

      ul {
        padding: 0px 40px;
        list-style-type: inherit;

        li {
          font-size: 18px;
          color: #fff;
          align-items: center;
          font-family: $family;
          margin-top: 10px;
        }
      }
    }

    &.terms_policyApplied {
      background-color: $back-theme;

      .heading_textarea {
        h3 {
          color: #fff;
        }
      }
    }
  }

  .contantabout {
    .main_heading {
      h1 {
        font-size: 64px;
        font-family: $family;
        color: $primary-color;
        font-weight: 600;
      }

      h2 {
        font-size: 42px;
        font-family: $family;
        color: $white-color;
        font-weight: 500;
      }
    }

    .heading_textarea {
      text-align: center;

      h2 {
        font-size: 30px;
        font-family: $family;
        font-weight: 700;
        color: $white-color;
      }

      p {
        font-size: 18px;
        color: $primary-color;
        align-items: center;
        font-family: $family;
        line-height: 25px;
        margin-top: 10px;
      }

      ul {
        padding: 0px 40px;

        li {
          font-size: 18px;
          color: $primary-color;
          align-items: center;
          font-family: $family;
          margin-top: 10px;
        }
      }
    }
  }
}

.copyrighttext {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;

  p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;

    span {
      color: $primary-color;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.copyrightapptext {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;

  p {
    color: #000;
    font-size: 16px;
    margin-bottom: 0px;

    span {
      color: $primary-color;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.termsmainsection {
  background-color: $input-color;
}

.errorText {
  color: red;
}

.heading_contactus h1 {
  font-size: 32px;
  color: $primary-color;
  font-family: $family;
  text-align: center;
}

.landingcard {
  height: 550px;
  border-radius: 10px;
  background-color: $input-color;
  padding: 20px;
  align-items: center;
}

.landingcard h3 {
  color: $primary-color;
  font-size: 24px;
  font-weight: 800;
  $font-family: $family;
}

.landingcard p {
  color: $primary-color;
  font-size: 18px;
  font-weight: 500;
  $font-family: $family;
}

.mainheading {
  text-align: center;
  align-items: center;

  h2 {
    font-size: 60px;
  }
}

.main_heading_text {
  padding-top: 10px;
}

.accounts {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin: 20px 0px;
  justify-content: center;
  gap: 30px;

  .backbtn {
    color: $primary-color;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: $family;
    font-size: 32px;
    font-weight: 500;
  }

  .backbtn:hover {
    color: #f3ad3d;
  }

  .mainheading {
    h2 {
      font-size: 32px;
      color: $primary-color;
      font-family: $family;
    }
  }
}

// Form container
.contactform {
  margin-top: 150px;

  .SigninForm {
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .formGroup {
      margin-bottom: 1.5rem;

      .inputGroup {
        position: relative;

        input {
          background-color: hsla(0, 0%, 85%, 0.278);
          border: 1px solid $primary-color;
          border-radius: 4px;
          color: $primary-color;
          font-size: 1rem;
          padding: 0.5rem 0.5rem 0.5rem 40px;
          width: 100%;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          color: $primary-color;
        }

        .toggleIcon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          cursor: pointer;
          color: $primary-color;
        }
      }

      .errorMessage {
        color: red;
        font-size: 0.875rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.submitButton2 {
  display: inline-block;
  color: $white-color;
  background-color: $primary-color;
  border: 1px solid $hover-color;
  border-radius: 4px;
  cursor: pointer;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 6px 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($hover-color, 10%);
  }

  &:disabled {
    background-color: lighten($hover-color, 20%);
    cursor: not-allowed;
  }
}

.submitButton_contact {
  display: inline-block;
  color: $white-color;
  background-color: $primary-color;
  border: 1px solid $hover-color;
  border-radius: 4px;
  cursor: pointer;
  font-family: $family;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 6px 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($hover-color, 10%);
  }

  &:disabled {
    background-color: lighten($hover-color, 20%);
    cursor: not-allowed;
  }
}

.popuarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: $primary-color;
  border: 2px solid $primary-color;
  padding: 20px;
  border-radius: 8px;

  .modalActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    .submitButton2 {
      display: inline-block;
      color: $white-color;
      background-color: $primary-color;
      border: 1px solid $hover-color;
      border-radius: 4px;
      cursor: pointer;
      font-family: $family;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      padding: 6px 20px;
      text-decoration: none;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($hover-color, 10%);
      }

      &:disabled {
        background-color: lighten($hover-color, 20%);
        cursor: not-allowed;
      }
    }
  }

  h4 {
    color: $white-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    font-family: $family;
  }

  p {
    color: $white-color;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }
}

// Media queries for responsiveness
.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}

.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}

.signupage {
  margin: 60px 0px 50px 0px;

  .signtop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;

    .backbtn {
      color: $primary-color;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .introText {
      text-align: center;

      h2 {
        font-size: 40px;
        color: $primary-color;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0px;
        font-family: $family;
      }
    }
  }

  .signupForm {
    background-color: $white-color;
    padding: 40px 60px;
    margin-bottom: 100px;

    .formGroup {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $white-color;

      .inputGroup {
        position: relative;
        margin: 10px 0px;

        .icon {
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
          color: $primary-color;
          border-radius: 50%;
          border: 2px solid $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          padding: 3px;
          cursor: pointer;
          text-align: center;
        }

        input {
          padding: 0.5rem 0.5rem 0.5rem 40px;
          font-size: 16px;
          border: 1px solid $primary-color;
          border-radius: 4px;
          color: $primary-color;
          background: rgba(217, 217, 217, 0.2784313725);
          width: 100%;
        }

        .toggleIcon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }

    .mainheading {
      text-align: center;
      align-items: center;
      margin: 20px 0px;
      justify-content: center;

      h2 {
        font-size: 40px;
        color: $primary-color;
        font-weight: 700;
      }
    }

    .errorMessage {
      color: red;
      font-size: 0.875rem;
      margin-top: -0.5rem;
      margin-bottom: 1rem;
    }

    .submitButton2 {
      color: $white-color;
      background-color: $primary-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-family: $family;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      padding: 8px 80px;
      text-decoration: none;

      &:hover {
        background-color: $hover-color;
      }
    }
  }

  .linkbtn {
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    font-family: $family;
  }

  .linkbtn:hover {
    color: $dark-color;
    text-decoration: none;
  }
}

.sectionsignup {
  padding: 0px;
  background-color: $white-color;

  .containerarea {
    padding: 0px;

    .imagesidesignup {
      display: block;

      .imgarea {
        height: 100%;

        .imagepoint {
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .logoimge {
            width: auto;
            height: 100px;
            left: 10px;
            top: 10px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .textareasignup {
            left: 10px;
            bottom: 10px;
            position: absolute;
            padding: 10px 200px 0px 0px;

            h2 {
              color: $white-color;
            }

            p {
              color: $white-color;
            }
          }
        }
      }
    }

    .signupagemain {
      padding: 50px 120px;

      .logingform {
        padding: 70px 20px;

        .signtop {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          margin-bottom: 20px;

          .backbtn {
            color: $primary-color;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .introText {
            text-align: center;

            h2 {
              font-family: $family;
              font-size: 40px;
              color: $primary-color;
              font-weight: 500;
              line-height: 1.6;
              margin-bottom: 0px;
            }
          }
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          width: 100%;

          .inputGroup {
            position: relative;
            margin: 10px 0px;

            .icon {
              position: absolute;
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
              color: $primary-color;
              border-radius: 50%;
              border: 2px solid $primary-color;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              padding: 3px;
              cursor: pointer;
              text-align: center;
            }

            input {
              padding: 0.5rem 0.5rem 0.5rem 40px;
              font-size: 16px;
              border: 1px solid $primary-color;
              border-radius: 4px;
              color: $primary-color;
              background: rgba(217, 217, 217, 0.2784313725);
              width: 100%;
            }

            .toggleIcon {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }
        }

        .mainheading {
          text-align: center;
          align-items: center;
          margin: 20px 0px;
          justify-content: center;

          h2 {
            font-size: 40px;
            color: $primary-color;
            font-weight: 700;
          }
        }

        .errorMessage {
          color: red;
          font-size: 0.875rem;
          margin-top: -0.5rem;
          margin-bottom: 1rem;
        }

        .submitButton2 {
          color: $white-color;
          background-color: $primary-color;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-family: $family;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          padding: 8px 80px;
          text-decoration: none;

          &hover {
            background-color: $hover-color;
          }
        }
      }

      .linkbtn {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        font-family: $family;
      }

      .linkbtn:hover {
        color: $dark-color;
        text-decoration: none;
      }
    }

    .ragistermain {
      padding: 0px 120px;

      .logingform {
        padding: 10px 0px;

        .signtop {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          margin-bottom: 20px;

          .backbtn {
            color: $primary-color;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .introText {
            text-align: center;

            h2 {
              font-family: $family;
              font-size: 40px;
              color: $primary-color;
              font-weight: 500;
              line-height: 1.6;
              margin-bottom: 0px;
            }
          }
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          width: 100%;

          .inputGroup {
            position: relative;
            margin: 10px 0px;

            .icon {
              position: absolute;
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
              color: $primary-color;
              border-radius: 50%;
              border: 2px solid $primary-color;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              padding: 3px;
              cursor: pointer;
              text-align: center;
            }

            input {
              padding: 0.5rem 0.5rem 0.5rem 40px;
              font-size: 16px;
              border: 1px solid $primary-color;
              border-radius: 4px;
              color: $primary-color;
              background: rgba(217, 217, 217, 0.2784313725);
              width: 100%;
            }

            .toggleIcon {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }
        }

        .mainheading {
          text-align: center;
          align-items: center;
          margin: 0px 0px;
          justify-content: center;

          h2 {
            font-size: 40px;
            color: $primary-color;
            font-weight: 700;
          }
        }

        .errorMessage {
          color: red;
          font-size: 0.875rem;
          margin-top: -0.5rem;
          margin-bottom: 1rem;
        }

        .submitButton2 {
          color: $white-color;
          background-color: $primary-color;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-family: $family;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          padding: 8px 80px;
          text-decoration: none;

          &:hover {
            background-color: $hover-color;
          }
        }
      }

      .linkbtn {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        font-family: $family;
      }

      .linkbtn:hover {
        color: $dark-color;
        text-decoration: none;
      }
    }
  }
}

.sectioncontact {
  padding: 0px;
  background-color: #28282e;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  .containerarea {

    //padding: 0px;
    .imagesidesignup {
      .contacttext {
        h2 {
          color: #fff;
          font-size: 48px;
          font-family: $family;
        }

        p {
          color: $primary-color;
          font-size: 20px;

          .linka {
            color: #fff !important;

            &:hover {
              color: $hover-color !important;
            }
          }
        }
      }
    }

    .contactforms {
      background-color: $white-color;
      border-radius: 10px;
      box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      margin: 0px 20px;
    }
  }
}

.staticimage {
  position: sticky;
  top: 90px;
  align-self: flex-start;
  padding-left: 0px !important;
}

.yourjurneycontentarea {
  h2 {
    font-size: 36px;
    font-weight: 700;
    font-family: $family;
    color: $dark-color;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    font-family: $family;
    color: $primary-color;
  }
}

.sectionsignuplogin {
  //background-color: $primary-color;
  //background-color: #90844f;
  padding: 50px 0px;

  .logosignup {
    text-align: center;
    padding: 20px 0px;

    .signuplogoimg {
      width: auto;
      height: 120px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-size: 18px;
      font-weight: 700;
      color: $primary-color;
      font-family: $family;
      padding: 10px 0px;
    }
  }

  .containermid {
    background-color: $primary-color;
    border-radius: 20px;
    //border: 1px solid $primary-color;
    box-shadow: $primary-color 0px 2px 8px 0px;

    .logincreatearea {
      .btnsignin {
        background-color: $white-color;
        // border-radius: 20px;
        border-top-left-radius: 20px;
        box-shadow: $primary-color 0px 2px 0px 0px;
        padding: 10px 0;
        text-align: center;
        // border: 1px solid $primary-color;
        cursor: pointer;
        color: $primary-color;
        font-size: 24px;

        &:hover {
          color: $white-color;
          background-color: $primary-color;
        }
      }

      .btnsignincreate {
        background-color: $white-color;
        border-top-right-radius: 20px;
        padding: 10px 0;
        text-align: center;
        box-shadow: $primary-color 0px 2px 0px 0px;
        //border: 1px solid $primary-color;
        cursor: pointer;
        color: $primary-color;
        font-size: 24px;

        &:hover {
          background-color: $primary-color;
          color: $white-color;
        }
      }
    }

    .signuploginform {
      padding: 20px 70px;

      .logingform {
        padding: 10px 0px;

        .applegoogel {
          text-align: center;
          padding: 30px 0px 20px 0px;

          .signtext {
            display: flex;
            align-items: center;
            text-align: center;
            color: $white-color;
            font-size: 18px;
            font-family: $family;
            margin-bottom: 20px;
          }

          .signtext::before,
          .signtext::after {
            content: "";
            flex: 1;
            border-bottom: 2px solid $white-color;
            margin: 0 10px;
          }

          p {
            color: $white-color;
            font-size: 18px;
            font-family: $family;
          }

          /*  .iocnsarea {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            .iconpoint {
              width: auto;
              cursor: pointer;
              height: 50px;
              border-radius: 15px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
          } */
          .iocnsareatapple {
            display: flex;
            justify-content: center;
            align-items: center;

            .iconpoint {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              background-color: $white-color;
              overflow: hidden;
              padding: 8px 10px;
              width: 225px;
              border: none;
              gap: 3px;
              border-radius: 6px;
              transition: .3s;

              .imagelogo {
                width: auto;
                height: 40px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                font-weight: 700;
                color: $primary-color;
                font-size: 17px;
                font-family: $family;
                margin-bottom: 0px;
              }
            }

            .iconpoint:hover {
              background-color: #000;

              .imagelogo {
                width: auto;
                height: 40px;
                overflow: hidden;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(187deg) brightness(103%) contrast(101%);

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                color: $white-color;
              }
            }
          }

          .iocnsareagoogel {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0px 10px 0px;

            .iconpoint {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              background-color: $white-color;
              overflow: hidden;
              padding: 8px 10px;
              border: none;
              width: 225px;
              gap: 3px;
              border-radius: 6px;
              transition: .3s;

              .imagelogo {
                width: auto;
                height: 40px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                color: $primary-color;
                font-size: 16px;
                font-family: $family;
                margin-bottom: 0px;
                font-weight: 700;
              }
            }

            .iconpoint:hover {
              background: rgb(66, 133, 244);
              background: linear-gradient(0deg, rgba(92, 134, 202, 1) 27%, rgba(182, 101, 94, 1) 91%);

              .imagelogo {
                width: auto;
                height: 40px;
                overflow: hidden;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(187deg) brightness(103%) contrast(101%);

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                color: $white-color;
              }
            }
          }
        }

        .signtop {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          margin-bottom: 20px;

          .backbtn {
            color: $white-color;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .introText {
            text-align: center;

            h2 {
              font-family: $family;
              font-size: 40px;
              color: $white-color;
              font-weight: 500;
              line-height: 1.6;
              margin-bottom: 0px;
            }
          }
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          width: 100%;

          .inputGroup {
            position: relative;
            margin: 10px 0px;

            .icon {
              position: absolute;
              top: 50%;
              left: 9px;
              transform: translateY(-50%);
              color: $primary-color;
              border-radius: 50%;
              border: 2px solid $primary-color;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              padding: 3px;
              cursor: pointer;
              text-align: center;
            }

            input {
              padding: 0.5rem 0.5rem 0.5rem 40px;
              font-size: 16px;
              border: 1px solid $primary-color;
              border-radius: 4px;
              color: $primary-color;
              background: $white-color;
              width: 100%;
            }

            .toggleIcon {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }
        }

        .mainheading {
          text-align: center;
          align-items: center;
          margin: 0px 0px;
          justify-content: center;
          padding: 10px 0px;

          h2 {
            font-size: 40px;
            color: $primary-color;
            font-weight: 700;
          }

          p {
            color: $white-color;
          }

          .linkbtn {
            color: $white-color;
            text-decoration: none;
            cursor: pointer;
            font-size: 18px;
            font-weight: 700;
            font-family: $family;
            padding: 0px 5px;
          }

          .linkbtn:hover {
            color: $dark-color;
          }
        }

        .errorMessage {
          color: #000;
          font-size: 0.875rem;
          margin-top: -0.5rem;
          margin-bottom: 1rem;
        }

        .submitButton2 {
          color: $white-color;
          background-color: $hover-color;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-family: $family;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          padding: 8px 80px;
          text-decoration: none;

          &:hover {
            background-color: $dark-color;
          }
        }
      }

      span {
        text-decoration: underline;

        .linkbtn {
          color: $white-color;
          text-decoration: underline;
          cursor: pointer;
          font-size: 18px;
          font-weight: 700;
          font-family: $family;
          padding: 0px 5px;
        }

        .linkbtn:hover {
          color: $dark-color;
          text-decoration: underline;
        }
      }
    }
  }
}

.bannertopimage1 {
  background-image: url(../assets/images/shapes/7thbackground.svg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 2;

  .paddingarea1 {
    padding: 25px 0px;
  }
}

.popuarealoking {
  .modal_header {
    border: none !important;
  }
}

.showListDots {
  padding-left: 2rem;
  list-style: disc;

  li {
    color: #fff;
  }
}

.bg_Blk_Clr {
  background: $back-theme;

  P {
    color: #fff;
  }
}

@media (max-width: 991px) {
  .taskarea {
    padding: 40px 20px 0px 20px !important;
  }

  .sectioncontact {
    .containerarea {
      .contactforms {
        margin: 50px 0px;
      }
    }
  }

  .staticimage {
    position: relative;
    top: 0px;
  }

  .landingcard {
    height: 440px;
    border-radius: 10px;
    background-color: $input-color;
    padding: 20px;
    align-items: center;
  }

  .textareasignup {
    padding: 10px 80px 0px 0px !important;
  }

  .signupagemain {
    padding: 50px 20px !important;
  }

  .ragistermain {
    padding: 0px 20px !important;
  }

  .popuarea {
    width: 70%;

    .popuarea h4 {
      font-size: 20px;
    }
  }

  .signupForm {
    background-color: $white-color;
    padding: 40px 20px !important;
  }

  .extraStyle {
    margin-bottom: 0px !important;
  }

  .signtop {
    gap: 20px !important;

    .introText {
      text-align: center;

      h2 {
        font-size: 30px !important;
        font-family: $family;
      }
    }
  }
}

@media (max-width: 768px) {
  .contactforms {
    background-color: $white-color;
    border-radius: 10px;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin: 0px 2x;
  }

  .sectioncontact {
    padding: 0px;
    background-color: #28282e;
    box-shadow:
      rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .containerarea {
      .imagesidesignup {
        display: block !important;
      }
    }
  }

  .imagesidesignup {
    display: none !important;
  }

  .signupagemain {
    padding: 50px 10px !important;
  }

  .textareasignup {
    padding: 10px 20px 0px 0px !important;
  }

  .signtop {
    gap: 20px !important;

    .introText {
      text-align: center;

      h2 {
        font-size: 30px !important;
      }
    }
  }

  .signupage {
    margin: 10px 0px;
  }

  .SigninForm {
    padding: 1rem;
  }

  .popuarea {
    width: 70%;

    h4 {
      font-size: 18px;
    }
  }

  .accounts .mainheading h2 {
    font-size: 28px;
  }

  input {
    font-size: 18px !important;
  }

  .mainheading h2 {
    font-size: 32px;
  }
}

@media (max-width: 575px) {
  .signuploginform {
    padding: 20px 50px !important;
  }

  .signupagemain {
    padding: 20px 20px 100px 0px !important;
    height: 100%;
  }

  .ragistermain {
    padding: 20px 60px !important;
  }

  .popuarea {
    width: 75%;

    h4 {
      font-size: 16px;
    }
  }

  .mainheading h2 {
    font-size: 28px;
  }

  input {
    font-size: 16px !important;
  }

  .submitButton2 {
    padding: 8px 40px !important;
  }
}

@media (max-width: 480px) {
  .signuploginform {
    padding: 20px 30px !important;
  }

  .signtop {
    gap: 10px !important;

    .introText {
      text-align: center;

      h2 {
        font-size: 24px !important;
      }
    }
  }

  .ragistermain {
    padding: 115px 30px !important;
  }

  .signupagemain {
    padding: 197px 20px !important;
    height: 100%;
  }

  .popuarea {
    width: 80%;

    h4 {
      font-size: 14px;
    }
  }

  .mainheading h2 {
    font-size: 24px;
  }
}

/* ======== */
@media (max-width: 1161px) {
  .staticimage {
    position: sticky;
    top: 90px;
    align-self: flex-start;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .signupForm {
    background-color: $white-color;
    padding: 40px 20px;
  }
}

@media (max-width: 1351px) {
  .signupagemain {
    padding: 50px 40px !important;
  }

  .ragistermain {
    padding: 0px 70px !important;
  }
}

@media (max-width: 576px) {
  .section_about {
    .headerpart {
      padding: 40px 0px;

      h1 {
        font-size: 36px;
        line-height: normal;
      }

      h2 {
        font-size: 28px;
        line-height: normal;
      }
    }

    .contantabout {
      .main_heading {
        h1 {
          font-size: 36px;
          line-height: normal;
        }

        h2 {
          font-size: 28px;
          line-height: normal;
        }
      }

    }
  }
}