@import "../../../assets/scss/variable.module.scss";
.whatarelooking {
  margin: 20px 0px 50px 0px;
  .mainheadingtop {
    margin: 10px 0px;
    h1 {
      font-size: 36px;
      color: $primary-color;
      font-weight: 500;
      text-align: center;
      line-height: 1.6;
      margin-bottom: 0px;
      font-family: $family;
    }
  }
  .dashboardForm {
    padding: 20px 0px;
    margin-top: 10px;
    .accounts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-top: 5px;
      color: $primary-color;
      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        text-align: left;
        color: #484848;
        font-family: $family;
      }
      .icondoc {
        color: $primary-color;
        font-size: 40px;
      }
      .iconsponts {
        display: flex;
        gap: 10px;
        span {
          color: $primary-color;
          cursor: pointer;
          width: 24px;
          height: 24px;
          cursor: pointer;
          border-radius: 50%;
          border: 2px solid $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 3px;
          .editcircelbtn {
            position: absolute;
            top: -9px;
            right: -9px;
            font-size: 26px;
          }
        }
      }
    }
    .accounts2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-top: 5px;
      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        text-align: left;
        color: #484848;
        font-family: $family;
      }
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        text-align: left;
        color: #484848;
      }
      .icondoc {
        color: $primary-color;
        font-size: 40px;
      }
      .iconsponts {
        display: flex;
        gap: 10px;
        span {
          color: $primary-color;
          cursor: pointer;
          width: 24px;
          height: 24px;
          cursor: pointer;
          border-radius: 50%;
          border: 2px solid $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 3px;
          .editcircelbtn {
            position: absolute;
            top: -9px;
            right: -9px;
            font-size: 26px;
          }
        }
      }
    }
    .accounts3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-top: 5px;
      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        text-align: left;
        color: #484848;
        font-family: $family;
      }
      .iconsponts {
        display: flex;
        gap: 10px;
        width: 0px;
        text-align: right;
        display: flex;
        justify-content: end;
        span {
          color: $primary-color;
          font-size: 24px;
          cursor: pointer;
        }
      }
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        text-align: left;
        color: #484848;
      }
      .icondoc {
        color: #976f1d;
        font-size: 40px;
        display: flex;
        align-items: center;
      }
      .statustext {
        text-align: left;
        h5 {
          color: #000;
        }
        p {
          color: #000;
          font-weight: 400;
          margin-bottom: 0px !important;
        }
      }
    }

    .accounts4 {
      display: flex;
      gap: 30px;
      align-items: center;
      padding: 0px 8px;
      margin-top: 5px;
      color: $primary-color;
      .icondoc {
        color: #976f1d;
        font-size: 40px;
        border: 1px solid #976f1d;
        border-radius: 20px;
        background-color: #fff;
        padding: 8px 10px;
        display: flex;
        align-items: center;
      }
      .statustext {
        text-align: left;
        h5 {
          color: #000;
        }
        p {
          color: #000;
          font-weight: 400;
          margin-bottom: 0px !important;
          font-size: 18px;
        }
      }
    }
    .accounts5 {
      padding: 25px 10px;
      text-align: center;
      p .linkarea {
        font-size: 16px;
        font-weight: 700;
        line-height: 15px;
        color: $primary-color;
        cursor: pointer;
      }
    }
    .accounts6 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-top: 5px;
      color: $primary-color;
      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        text-align: left;
        color: #484848;
        font-family: $family;
      }
      .icondoc {
        color: $primary-color;
        font-size: 40px;
      }
      .iconsponts {
        display: flex;
        gap: 10px;
        width: 50px;
        text-align: right;
        display: flex;
        justify-content: end;
        span {
          color: $primary-color;
          font-size: 24px;
          cursor: pointer;
          white-space: nowrap;
          font-weight: 700;
        }
      }
    }
  }
}

.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
    font-family: $family;
  }
}

.taxbtn {
  color: #484848;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  padding: 0px 10px;
}

.taxbtn:hover {
  background-color: $primary-color;
  color: #fff;
}
.rightsidetext {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.rightsidetext p {
  font-family: $family;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
  color: $secondary-color;
  align-items: center;
}
.rightsidetext .rightside {
  color: $secondary-color;
  font-size: 20px;
}
.popuarea {
  background-color: #976f1d;
  border: 2px solid #976f1d;
  padding: 20px;
  border-radius: 8px;

  .modalActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  h4 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    font-family: "JetBrains Mono", monospace; /* Ensure you have this font imported */
    text-align: center;
  }

  p {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
  }
}

.logo {
  margin-bottom: 20px;
  text-align: center;
}

.centeredLogo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.modal-content {
  border: 0px !important;
}

@media (max-width: 545px) {
  .accounts3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px !important;
    margin-top: 5px;
    h2 {
      font-size: 16px !important;
      font-weight: 700;
      line-height: 40px !important;
      text-align: left;
      color: #484848;
    }
    .iconsponts {
      display: flex;
      text-align: right;
      display: flex;
      justify-content: end;

      span {
        color: $primary-color;
        font-size: 18px !important;
        cursor: pointer;
      }
    }
    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      text-align: left;
      color: #484848;
    }
    .icondoc {
      color: #976f1d;
      font-size: 40px;
      display: flex;
      align-items: center;
    }
    .statustext {
      text-align: left;
      h5 {
        color: #000;
      }
      p {
        color: #000;
        font-weight: 400;
        margin-bottom: 0px !important;
      }
    }
  }
}
@media (max-width: 480px) {
  .accounts3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px !important;
    margin-top: 5px;
    h2 {
      font-size: 16px !important;
      font-weight: 700;
      line-height: 20px !important;
      text-align: left;
      color: #484848;
    }
    .iconsponts {
      display: flex;
      text-align: right;
      display: flex;
      justify-content: end;

      span {
        color: $primary-color;
        font-size: 18px !important;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 420px) {
  .accounts3 {
    .iconsponts {
      display: flex;
      text-align: right;
      display: flex;
      justify-content: end;
      width: 10% !important;
      span {
        color: $primary-color;
        font-size: 18px !important;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 340px) {
  .accounts3 {
    h2 {
      font-size: 15px !important;
      font-weight: 700;
      line-height: 20px !important;
      text-align: left;
      color: #484848;
    }
    .iconsponts {
      display: flex;
      text-align: right;
      display: flex;
      justify-content: end;
      width: 16% !important;
      span {
        color: $primary-color;
        font-size: 15px !important;
        cursor: pointer;
      }
    }
  }
}
