@import "../../../assets//scss/variable.module.scss";

.mainheadingUploaddocument {
  margin: 20px 0px 10px 0px;

  h1 {
    font-size: 36px;
    color: $primary-color;
    font-weight: 500;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 0px;
    font-family: $family;
  }
}

.TechnicalanalysisForm {
  background-color: #fff;
  padding: 20px 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  width: 100%;
  max-height: 76vh;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #61dafb;
  }
}

.mainheading {
  text-align: center;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;

  h2 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
  }
}

.accounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  margin-top: 5px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    margin-bottom: 2px;
    color: $primary-color;
    text-align: left;
    font-family: $family;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }

  .inputGroup {
    position: relative;
    margin: 10px 0px;

    textarea {
      padding: 0.5rem 0.5rem 40px;
      font-size: 1rem;
      border: 1px solid $primary-color;
      border-radius: 4px;
      color: $primary-color;
      background: $input-color;
      width: 100%;
      height: 100px;
      resize: vertical;
    }

    textarea::placeholder {
      color: $input-color;
      font-size: 0.875rem; // Placeholder font size
    }

    textarea:focus {
      outline: none;
      border-color: $primary-color;
      box-shadow: 0 0 5px hsla(0, 0%, 85%, 0.278);
    }

    .toggleIcon {
      position: absolute;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      bottom: 0%;
      color: $primary-color;
      font-size: 22px;
      gap: 10px;
      display: flex;
    }
  }
}

p {
  color: $primary-color;
  font-size: 20px;
  font-weight: 500;
  font-family: $family;
}

.backbtn {
  color: $primary-color;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 24px;
  font-weight: 500;
}

.backbtn:hover {
  color: #f3ad3d;
}

.submitButton {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  text-decoration: none;
}

.submitButton:hover {
  background-color: #c3b581;
}

.submitButton2 {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 8px 80px;
  text-decoration: none;
}

.submitButton2:hover {
  background-color: #c3b581;
}

.submitButton3 {
  color: #fff;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $family;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  text-decoration: none;
}

.submitButton3:hover {
  background-color: #c3b581;
}

.linkbtn {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}

.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}

.linkbtn2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  font-family: $family;
}

.linkbtn:hover {
  color: $dark-color;
  text-decoration: none;
}

.rmdp-calendar {
  height: max-content;
  padding: 4px;
  width: 500px !important;
}

.rmdp-day-picker {
  padding: 5px;
  display: block !important;
}

.popuarea {
  background-color: #fff;
  border: 2px solid #fff;
  padding: 20px;
  border-radius: 8px;

  .modalActions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    gap: 20px;
  }

  h4 {
    color: #28282e;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }

  p {
    color: #28282e;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    font-family: $family;
    text-align: center;
  }
}

.logo {
  margin-bottom: 5px;
  text-align: center;
}

.centeredLogo {
  width: 100px;
  height: 100px;

  object-fit: contain;
}

.modal-content {
  border: 0px !important;
}

@media (max-width: 767px) {
  .mainheading h2 {
    font-size: 32px;
  }

  .backbtn {
    font-size: 23px;
    font-weight: 500;
  }

  .rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 450px !important;
  }
}

@media (max-width: 575px) {
  .mainheading h2 {
    font-size: 28px;
  }

  .rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 350px !important;
  }
}

@media (max-width: 468px) {
  .mainheading h2 {
    font-size: 24px;
  }

  .rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 320px !important;
  }

  .submitButton2 {
    padding: 8px 28px;
  }
}

// @media (min-width: 576px) {

//   .modal-dialog {
//     width: 330px;
//   }
// }