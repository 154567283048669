@import "../../../assets/scss/variable.module.scss";
.applicationStatuspage {
  margin: 20px 0px 50px 0px;
  .technicalanalysis {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;

    .backbtn {
      color: #976f1d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .introText {
      text-align: center;
      h2 {
        font-size: 36px;
        color: $primary-color;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 0px;
        font-family: $family;
      }
    }
  }
  .termsconditionsForm {
    background-color: #fff;
    padding: 0px 20px;
    margin-bottom: 40px;
    border: 10px;
    .applicationStatusContainer {
      font-family: Arial, sans-serif;
      padding: 20px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 8px;

      .header {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        justify-content: end;

        .setting i {
          font-size: 28px !important;
        }

        .navbar-toggler {
          font-size: 28px !important;
        }
      }

      .back-arrow {
        font-size: 24px;
        margin-right: 10px;
        cursor: pointer;
      }

      h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }

      .status-circle {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 10px;
        position: absolute;
        top: 0px;
        left: -8px;
      }

      .statusList {
        border-left: 4px solid #8d8556;
        margin-bottom: 20px;
        position: relative;

        .status-item {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &.active {
            color: #000;

            .status-circle {
              background-color: #ffffff;
              border: 2px solid #8d8556;
            }
          }
          span {
            font-family: $family;
          }
          .status-circle {
            width: 13px;
            height: 13px;
            border-radius: 50%;

            margin-right: 10px;
            position: relative;
          }
        }
      }

      .footer {
        font-size: 14px;
        color: #4a5568;
      }
    }
  }
}
@media (max-width: 545px) {
  .applicationStatusContainer {
    padding: 20px 0px !important;
  }
}
@media (max-width: 480px) {
  .technicalanalysis {
    gap: 10px !important;

    .introText {
      text-align: center;
      h2 {
        font-size: 24px !important;
      }
    }
  }
}
@media (max-width: 400px) {
  .applicationStatusContainer {
    .status-circle {
      height: 10px !important;
      left: -7px !important;
    }
  }
}
@media (max-width: 320px) {
  .applicationStatusContainer {
    .status-circle {
      width: 15px !important;
      height: 10px !important;
      border-radius: 50%;
      margin-right: 10px;
      position: absolute;
      top: 0px;
      left: -7px !important;
    }
  }
}
