@import "../../../assets/scss/variable.module.scss";
.footer {
  background-color: #fff;
}
.footerlogo {
  width: auto;
  height: 140px;
  img {
    width: 100%;
    height: 100%;
  }
}

.footeritems {
  margin: 0px auto !important;
}
.brandname {
  padding: 0px 17px;
  h2 {
    font-size: 28px;
    font-family: $family;
    font-weight: 800;
    color: $primary-color;
  }
}
.iconsarea {
  margin-right: 0px !important;
  .iconpart {
    font-size: 28px;
    font-family: $family;
    font-weight: 800;
    color: $dark-color;
    padding: 0px 10px;
    margin-right: 0px !important;
    &:hover {
      color: $primary-color;
    }
  }
}

.titlefooter {
  font-size: 24px !important;
  color: $primary-color !important;
  font-weight: 700 !important;
  font-family: $family !important;
}
.namesfooter {
  font-size: 20px !important;
  color: $dark-color !important;
  font-weight: 500 !important;
  font-family: $family !important;
  &:hover {
    color: $primary-color !important;
  }
}
.copyrightline {
  background-color: $back-theme;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
}
@media (max-width: 991px) {
  .footeritems {
    margin: 20px auto !important;
  }
  .footerlogo {
    width: auto;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 420px) {
  .footerlogo {
    width: auto;
    height: 100px;
  }
}
