@import "../../../assets/scss/variable.module.scss";
.tableheading {
  text-align: center;
  margin: 20px 0px 5px 0px;
  display: flex;
  justify-content: end;
  h1 {
    font-size: 36px;
    color: $primary-color;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 0px;
    font-family: $family;
  }

  .btnsyncdata {
    padding: 6px 20px;
    background-color: $primary-color;
    color: #fff;
    display: flex;
    align-items: baseline;
    font-size: 18px;
    font-size: $family;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    &:hover {
      background-color: $hover-color;
    }
  }
}

.reporttablesection {
  background-color: #fff;
  padding: 20px 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  width: 100%;
  max-height: 80vh;
  margin: 20px 0px 50px 0px;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #61dafb;
  }

  .main_dataarea {
    padding: 20px 0px;
    h2 {
      text-align: center;
      font-size: 38px;
      font-family: $family;
      color: $primary-color;
      font-weight: 700;
    }
    h3 {
      text-align: center;
      font-size: 30px;
      font-family: $family;
      color: $dark-color;
      font-weight: 700;
    }
    p {
      text-align: center;
      font-size: 22px;
      font-family: $family;
      color: #494228;
    }
  }
}
.contentpopint {
  ul {
    li {
      color: $primary-color !important;
      font-size: 18px;
      font-family: $family;
      gap: 2px;
      strong {
        color: $dark-color !important;
        font-size: 20px;
        font-weight: 600;
        padding: 0px 10px 0px 0px;
      }
    }
  }
}
.lodertext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 32px;
    font-family: $family;
    color: $primary-color;
  }
}
